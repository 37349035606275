<template>
  <div class="order-now">
    <div class="order-main">
      <div class="order-steps">
        <!-- 第一步 -->
        <div class="order-steps-item">
          <span class="steps-number" :class="{ bg: step >= 1 }">01</span>
          <div class="text-div">
            <span class="title-text">选择路线服务</span>
          </div>
          <div class="line-div">
            <div class="circle" :class="{ bc: step >= 2 }"></div>
            <div class="line" :class="{ bc: step >= 2 }"></div>
            <div class="circle" :class="{ bc: step >= 2 }"></div>
          </div>
        </div>
        <!-- 第二步 -->
        <div class="order-steps-item">
          <span class="steps-number" :class="{ bg: step >= 2 }">02</span>
          <div class="text-div">
            <span class="title-text">选择送件方式</span>
          </div>
          <div class="line-div">
            <div class="circle" :class="{ bc: step >= 3 }"></div>
            <div class="line" :class="{ bc: step >= 3 }"></div>
            <div class="circle" :class="{ bc: step >= 3 }"></div>
          </div>
        </div>
        <!-- 第三步 -->
        <div class="order-steps-item">
          <span class="steps-number" :class="{ bg: step >= 3 }">03</span>
          <div class="text-div">
            <span class="title-text">填写包裹信息</span>
          </div>
        </div>
      </div>
      <!-- 上一步按钮 -->
      <div class="pev-btn posi" v-if="step !== 1" @click="pevStep">上一步</div>
      <!-- 步骤一 -->
      <Step1 v-if="step === 1" @nextStep="nextStep"></Step1>
      <!-- 步骤二 -->
      <Step2 v-if="step === 2" @nextStep="nextStep"></Step2>
      <!-- 步骤三 -->
      <Step3 v-if="step === 3" @nextStep="nextStep"></Step3>
    </div>
  </div>
</template>
<script>
import Step1 from "./step1.vue";
import Step2 from "./step2.vue";
import Step3 from "./step3.vue";
window.addEventListener("message", function (event) {
  //event.data获取传过来的数据
  var origin = event.origin;
  // console.log(event);
  let data = event.data;
  // console.log(event.data);
  if ("whereSend" in data) {
    // console.log("something is pok" + data);

    sessionStorage.setItem("step", 1);
    sessionStorage.setItem("updateItemInfo", JSON.stringify(event.data));
  }
});
export default {
  name: "orderNow",
  components: { Step1, Step2, Step3 },
  data() {
    return {
      // step:1,
    };
  },
  created() {
    if (sessionStorage.getItem("step")) {
      this.$store.state.step = Number(sessionStorage.getItem("step"));
      sessionStorage.removeItem("step");
    }
  },

  computed: {
    step() {
      console.log("111111111111111" + this.$store.state.step);
      return this.$store.state.step;
    },
  },
  methods: {
    // 下一步
    nextStep(obj) {
      // this.step++;
      this.$store.state.step = obj.step;
      console.log(obj);
    },
    // 上一步
    pevStep() {
      this.$store.state.step = this.$store.state.step - 1;
    },
  },
};
</script>
<style lang="less" scoped>
.order-now {
  min-height: calc(100vh - 101px);
  background-color: #f4f4f4;
  position: relative;
  .order-main {
    width: 1000px;
    margin: 0 auto;
    .order-steps {
      display: flex;
      height: 190px;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      .order-steps-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .steps-number {
          width: 50px;
          display: inline-block;
          height: 50px;
          line-height: 50px;
          text-align: center;
          border-radius: 50%;
          background-color: #d2d5dc;
          color: #fff;
          margin-right: 20px;
          font-size: 24px;
          font-weight: bold;
        }
        .text-div {
          .title-text {
            height: 33px;
            font-size: 24px;
            font-weight: bold;
            color: #1e2b4f;
            line-height: 33px;
          }
        }
        .line-div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin: 22px 15px;
          .circle {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            border: 1px solid #d2d5dc;
          }
          .line {
            width: 119px;
            border-bottom: 1px dotted #d2d5dc;
          }
          .bc {
            border-color: #6d5efe;
          }
        }
        .bg {
          background-color: #6d5efe;
        }
      }
    }
  }
  .pev-btn {
    height: 40px;
    width: 80px;
    line-height: 40px;
    text-align: center;
    border: 1px solid @bg-color;
    color: @bg-color;
    border-radius: 22px;
    font-size: 14px;
    background-color: #fff;
    margin-bottom: 40px;
  }
}
</style>

<template>
  <div class="step3">
    <!-- 寄件人 -->
    <Sender
      :sender="sender"
      @sender="(status) => (senderStatus = status)"
      @updateSender="updateSender"
    ></Sender>
    <!-- 收件人 -->
    <Address
      :addressArr="addressArr"
      :gateSign="gateSign"
      :serviceLine="serviceLine"
      ref="address"
      @updateAddress="updateAddress"
      @addAddress="addAddress"
      v-show="senderStatus"
    ></Address>

    <!-- 服务介绍 -->
    <div class="service-introduction">
      <div class="introduction-title">服务介绍</div>
      <div id="introduce" class="introduceText"></div>
      <!-- <div style="font-size: 14px; font-weight: 400; color: #1e2b4f">
        <p>
          <br />
        </p>
        <p>
          <span style="background-color: #0000ff; color: #ffffff"
            >&nbsp; &nbsp; 包装规则&nbsp; &nbsp; &nbsp;</span
          >
        </p>
        <p><br /></p>
        <ol>
          <li>此路线可邮寄两罐奶粉整个包裹重量上限为3公斤</li>
          <li>
            该服务不计体积重，但也不能使用太过夸张的纸箱进行包装&nbsp;&nbsp;
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <span style="color: #ffffff; background-color: #0000ff"
            >&nbsp; &nbsp; 邮寄规则&nbsp; &nbsp; &nbsp;</span
          >
        </p>
        <p>
          <br />
        </p>
        <ol>
          <li>需要如实申报箱内物品，只允许邮寄申报列表内的物品</li>
          <li>
            如因夹带或没有如实申报导致被海关罚没的包裹，由下单人承担相应风险&nbsp;
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <span style="color: #ffffff; background-color: #0000ff"
            >&nbsp; &nbsp; 赔付标准&nbsp; &nbsp; &nbsp;</span
          >
        </p>
        <p>
          <br />
        </p>
        <div>该服务仅提供以下保险：</div>
        <ol>
          <li>丢失保险：上限为18英镑/箱；国际运费全额退还</li>
          <li>
            破损保险：<span style='font-family:"'
              >当包裹外箱破损而造成的内件破损可代为向国内派送公司索赔</span
            >（奶粉需要使用气柱和双层瓦楞纸厚箱正规包装，否则不授予破损理赔）
          </li>
        </ol>
        <p></p>
        <p style="font-size: 14px; background-color: #ffffff">特别提示：</p>
        <ol style="font-size: 14px; background-color: #ffffff">
          <li>
            以上保险服务均由终端物流商提供，若需索赔Send2china速递中国会替客户代为申请，具体赔付金额是以终端物流商的决定结果为准。
          </li>
          <li>
            包裹索赔所需要的文件以国内提出的需求为准；任何索赔都需要提供包裹内件对应的小票照片，若包裹破损还需要提供国内快递员/快递公司开设的包裹破损证明。
          </li>
        </ol>
        &nbsp;
        <p></p>
        <span style="color: #ffffff; background-color: #0000ff"
          >&nbsp; &nbsp; 邮寄流程&nbsp; &nbsp; &nbsp;</span
        >
        <p>
          <br />
        </p>
        <ol>
          <li>打包完成后，可以在网站7天24小时自由下单</li>
          <li>填写包裹的重量和长宽高后，选择估算运费或立即下单</li>
          <li>在选择线路服务页面，选择对应的服务路线</li>
          <li>
            我们提供三种方式把包裹交给我们，分别是自送网点，上门取件，自行安排；请选择对您最为合适的方式
          </li>
          <li>在线填写寄件人信息和收件人信息，填写包裹内件信息</li>
          <li>
            支付订单后，系统会自动生成快递面单，请进入我的账户进行下载打印
          </li>
        </ol>
        <p>
          <br />
        </p>
        <span style="color: #ffffff; background-color: #0000ff"
          >&nbsp; &nbsp;&nbsp;</span
        ><span style="color: #ffffff; background-color: #0000ff"
          >需要请您额外注意的是：</span
        ><span style="background-color: #0000ff; color: #ffffff"
          >&nbsp; &nbsp;</span
        ><span style="background-color: #0000ff; color: #ffffff">&nbsp;</span>
        <p>
          <br />
        </p>
        <ol>
          <li>
            若您选择上门取件，司机上门取件不会提前打电话通知，需要您在家里等待一整天直至司机完成取件；如果您居住的是高层公寓，出于公寓安全考虑，司机一般不会上楼，请提前贴好面单并将包裹送至reception（前台），并确保reception（前台）把包裹交给正确的取件公司
          </li>
          <li>
            包裹出单成功后，请将Label（面单）牢固的黏贴在纸箱最大面积的一面
          </li>
          <li>
            包裹到达我们仓库后通常会在24-48小时内扫描，并发往机场，如果您的包裹在投递到我们仓库后3个工作日内未进行扫描或任何状态更新，请立即与我们联系
          </li>
        </ol>
        <div>
          <br />
        </div>
        <hr />
        <div>包裹常见问题汇总：点击此处查看<br /></div>
      </div> -->
    </div>

    <!-- 提交订单 -->
    <div v-if="senderStatus" class="submit">
      <el-button class="submit-btn" @click="submit">提交订单</el-button>
    </div>

    <!-- 包裹清单按钮 -->
    <div
      v-if="showPackageBtn"
      class="package-right-btn package-btn posi"
      @click="
        packageShow = true;
        showPackageBtn = false;
      "
    >
      <i class="icon-text el-icon-d-arrow-left"></i>
      <div class="btn-text">点击展开包裹清单</div>
      <div class="package-count">{{ packageData.length }}</div>
    </div>
    <!-- 注意事项和服务条款 -->
    <div class="terms-div">
      <el-dialog
        title=""
        :visible.sync="termsVisible"
        width="600px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <div class="dialog-content">
          <div class="attention-div">
            <div class="title">注意事项</div>
            <div
              v-if="
                !!serviceLine &&
                (serviceLine.identityNum || serviceLine.identityPicture)
              "
              class="content"
            >
              <span class="content-text">此服务</span>
              <span class="content-text red-text"
                ><b>建议上传身份证照片正反面</b></span
              >
              <span class="content-text">用以及时清关，点击</span>
              <span
                class="content-text blue-text posi"
                @click="uploadIDCardClicked"
                >此处上传。</span
              >
            </div>
            <div v-else class="content">
              <span class="content-text">此服务路线无须进行身份验证</span>
            </div>
          </div>
          <div class="terms-content">
            <div class="title">服务条款</div>
            <div class="content">
              <div>
                <p
                  style="
                    line-height: 20px;
                    margin-top: 15px;
                    margin-bottom: 10px;
                  "
                >
                  1、我已经阅读并同意速递中国服务条款，并且确认所邮寄的快递物品不含有任何航空违禁物品。
                </p>
                <p style="line-height: 20px; margin-bottom: 10px">
                  2、我同意若本人提供的包裹尺寸或重量与运营商的测量不符导致的额外费用，速递中国有权从我所提供的银行卡信息对应的银行账户内扣除相应费用。
                </p>
                <p style="line-height: 20px; margin-bottom: 2px">
                  3、我如实申报了包裹内物品信息（种类/数量/价值）如由于瞒报漏报而产生了额外税金、清关延误或者海关退运，我将自行承担后果
                </p>
              </div>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="termsVisible = false">取 消</el-button>
          <el-button type="primary" @click="orderClicked" :loading="loading"
            >我已阅读，并确认包裹填写无误</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- 包裹清单抽屉 -->
    <div class="package-div">
      <el-drawer
        title="包裹清单"
        :visible.sync="packageShow"
        direction="rtl"
        :size="325"
        :show-close="false"
        :modal="false"
        :wrapperClosable="false"
        :modal-append-to-body="true"
        @closed="showPackageBtn = true"
      >
        <div class="package-content">
          <div
            v-for="(item, index) in packageData"
            :key="index"
            class="package-item"
          >
            <div class="item-line">
              <div class="package-name">
                <span>包裹{{ index + 1 }} (→</span>
                <span class="name">{{ item.addresss }}</span>
                <span>)</span>
              </div>
              <div class="package-amount">小计：£{{ item.account }}</div>
            </div>
            <div class="item-line" style="margintop: 6px">
              <div class="serve-name">{{ item.serveName }}</div>
              <div class="package-weight">计价重量：{{ item.weight }}kg</div>
            </div>
            <div class="item-line" style="margintop: 12px">
              <div class="line-text">
                国际运费：£{{ item.internationalShippingFee }}
              </div>
              <div class="line-text">境内费用：£{{ item.inCharge }}</div>
            </div>
            <div class="item-line" style="margintop: 6px">
              <div class="line-text">附加费：£{{ item.additionalCharge }}</div>
              <div class="line-text">预缴税金：£{{ item.prepayTaxes }}</div>
            </div>
            <div class="item-line" style="margintop: 6px">
              <div class="line-text">保险费：£{{ item.premium }}</div>
              <div class="line-text">燃油附加费：£{{ item.fuelSurcharge }}</div>
            </div>
            <div class="item-line" style="margintop: 6px">
              <div class="line-text">
                增值服务费：£{{ item.addedServiceFee }}
              </div>
            </div>
          </div>
        </div>
        <div class="package-bottom">
          <div class="package-count-div">
            <div class="normal-text">共</div>
            <div class="count-text">{{ packageData.length }}个</div>
            <div class="normal-text">包裹</div>
          </div>
          <div class="package-acount-div">
            <div class="normal-text">总金额：</div>
            <div class="account-text">£22000.50</div>
          </div>
        </div>
        <!-- 包裹清单按钮 -->
        <div
          class="package-btn package-close-btn posi"
          @click="packageShow = false"
        >
          <i class="icon-text el-icon-d-arrow-right"></i>
          <div class="btn-text">点击收起包裹清单</div>
          <div class="package-count">{{ packageData.length }}</div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import Sender from "@components/order/sender";
import Address from "@components/order/addressee";
import { defaultSenderAddress } from "../../api/address/sender/sender";
import { defaultConsigneeAddress } from "../../api/address/consignee/consignee";
import {
  addOrderPackage,
  getServiceIntroduce,
  updatePackageStatus,
} from "@/api/order/order";
import { createOrder } from "@/api/transport/transport";
export default {
  name: "step3",
  components: { Sender, Address },
  data() {
    return {
      gateSign: "",
      loading: false,
      // 是否显示收件人
      senderStatus: false,
      // 寄件人数据
      sender: {
        name: "",
        phone: "",
        zipCode: "",
        addressOne: "",
        addressTwo: "",
        addressThree: "",
        addressFour: "",
        defaultFlag: true,
      },
      lineId: this.$store.getters.getLineId,
      // 收件人数组
      addressArr: [
        {
          // 收件人信息  姓名，手机号，身份证号，城市，详细地址
          consignee: {
            name: "", // 收件人姓名
            identityNumber: "", // 身份证号
            phone: "", // 收件人手机号
            province: "", // 省
            city: "", // 市
            county: "", // 区
            postCode: "", // 邮编
            addressDetail: "", // 详细地址
            retained: [], // 三级联动值
          },
          // 包裹信息
          packageList: [
            {
              length: this.$store.getters.getItemInfo.length,
              width: this.$store.getters.getItemInfo.width,
              height: this.$store.getters.getItemInfo.height,
              weight: this.$store.getters.getItemInfo.weight,
              // 保险服务
              insuranceId: this.$store.state.insurance,
              // 包裹备注
              note: "",
              // 物品
              goodsList: [
                {
                  // 物品名称id
                  id: "",
                  // 物品数量
                  number: 1,
                  // 物品单价
                  price: "",
                  // 物品名称(文本)
                  name: "",
                  // 物品类别
                  type: "",
                  sku: "",
                },
              ],
            },
          ],
        },
      ],
      // 服务条例是否显示
      termsVisible: false,
      // 右侧包裹清单打开按钮是否显示
      showPackageBtn: false,
      // 包裹清单是否展开
      packageShow: false,
      // 包裹详情信息
      packageData: [
        {
          addresss: "中国大陆北京上百大声道",
          account: "22000.50",
          serveName: "两罐奶粉两罐奶粉两罐奶粉两罐奶粉",
          weight: "20.00",
          //国际运费
          internationalShippingFee: "20000.00",
          //境内费用
          inCharge: "20000.00",
          //预缴税金
          prepayTaxes: "20000.00",
          //附加费
          additionalCharge: "20000.00",
          //保险费
          premium: "20000.00",
          //燃油附加费
          fuelSurcharge: "20000.00",
          //增值服务费
          addedServiceFee: "20000.00",
        },
        {
          addresss: "中国大陆北京上百大声道",
          account: "22000.50",
          serveName: "两罐奶粉两罐奶粉两罐奶粉两罐奶粉",
          weight: "20.00",
          //国际运费
          internationalShippingFee: "20000.00",
          //境内费用
          inCharge: "20000.00",
          //预缴税金
          prepayTaxes: "20000.00",
          //附加费
          additionalCharge: "20000.00",
          //保险费
          premium: "20000.00",
          //燃油附加费
          fuelSurcharge: "20000.00",
          //增值服务费
          addedServiceFee: "20000.00",
        },
      ],
      serviceLine: {},
    };
  },
  mounted() {
    console.log(this.$store.getters.getLineId, "lineID");
    this.getServiceIntroduce();
    //设置默认寄件人
    this.getDefaultSender().then((senderDefault) => {
      if (senderDefault) {
        this.sender = { ...senderDefault };
      }
    });
    //设置默认收件人
    this.getDefaultConsignee().then((consigneeDefault) => {
      if (consigneeDefault) {
        // consigneeDefault.retained = [consigneeDefault.province,consigneeDefault.city,consigneeDefault.county+" "+ "(邮编:" + consigneeDefault.postCode + ")"]
        consigneeDefault.retained = [
          consigneeDefault.province,
          consigneeDefault.city,
          consigneeDefault.county,
        ];
        this.addressArr.forEach((item) => {
          item.consignee = { ...consigneeDefault };
        });
      }
    });
  },
  methods: {
    // 获取服务介绍
    async getServiceIntroduce() {
      console.log("AAAAAAAAA");
      await getServiceIntroduce(this.$store.getters.getLineId).then((res) => {
        console.log(res);
        console.log(this.parseToDOM(res.data.data.info.serviceIntroduction));
        // let tem = this.parseToDOM(res.data.data.info.serviceIntroduction);
        document.getElementById("introduce").innerHTML =
          res.data.data.info.serviceIntroduction;
        this.serviceLine = res.data.data.info;
        //获取关口标识
        // this.gateSign = res.data.data.info.clcSign ? res.data.data.info.clcSign : "HK";
        this.gateSign = res.data.data.info.clcSign;
        console.log(this.gateSign);
      });
    },
    //获取默认寄件人
    async getDefaultSender() {
      let data;
      await defaultSenderAddress().then((res) => {
        if (res.data && res.data.data && res.data.data.default) {
          data = res.data.data.default;
        }
      });
      return data;
    },
    //获取默认收件人
    async getDefaultConsignee() {
      let data;
      await defaultConsigneeAddress().then((res) => {
        if (res.data && res.data.data && res.data.data.default) {
          data = res.data.data.default;
        }
      });
      return data;
    },
    //添加包裹
    addAddress(index) {
      console.log("添加收件人", index);
      this.getDefaultConsignee().then((consigneeDefault) => {
        if (consigneeDefault) {
          consigneeDefault.retained = [
            consigneeDefault.province,
            consigneeDefault.city,
            consigneeDefault.county +
              " " +
              "(邮编:" +
              consigneeDefault.postCode +
              ")",
          ];
          this.addressArr[index].consignee = { ...consigneeDefault };
        }
      });
    },
    parseToDOM(str) {
      var div = document.createElement("div");
      if (typeof str == "string") div.innerHTML = str;
      return div.childNodes;
    },
    submit() {
      // this.loading = true;
      // 所有收件人信息校验
      let addressRef =
        this.$refs.address.$refs.addressForm.$refs["addressForm"];
      // 所有包裹表单
      let packageRef = [];
      // 整理去重后需要验证的所有表单
      let validateArr = [];

      // 遍历所有收件人组件
      for (let i = 0; i < addressRef.length; i++) {
        // 获取所有包裹组件
        let packageTemp = this.$refs.address.$refs.addressForm.$refs.package;
        // 遍历所有包裹
        for (let i = 0; i < packageTemp.length; i++) {
          // 把每个包裹中的物品表单添加到包裹表单数组中
          packageRef.push(...new Set(packageTemp[i].$refs.packageForm));
          console.log(
            packageTemp[i].$refs.sizeForm,
            "packageTemp[i].$refs.sizeForm"
          );
          packageRef.push(...new Set(packageTemp[i].$refs.sizeForm));
        }
      }
      // 合并 物品表单以及收件人表单并去重
      validateArr = [...new Set(packageRef), ...new Set(addressRef)];
      // 记录所有表单验证的结果， 全是 true 验证通过，有一个false 验证失败
      let resArr = [];
      // 循环所有需要验证的表单进行验证
      for (let i = 0; i < validateArr.length; i++) {
        validateArr[i].validate((valid) => {
          if (valid) {
            // 验证成功往结果数组添加true
            resArr.push(true);
          } else {
            // 验证失败往结果数组添加false
            resArr.push(false);
            return false;
          }
        });
      }
      // 判断表单验证结果数组中有没有false 有，阻止请求接口
      if (resArr.includes(false)) {
        console.log("error submit!!");
      } else {
        // 表单验证成功，请求接口
        // alert('submit!');
        this.termsVisible = true;
        console.log("addressArr", this.addressArr);
      }
    },
    createOrderPost(upData) {
      let data = "package_id=" + upData.package_id + "&sn=" + upData.sn;
      console.log("-----------------------------------");
      console.log(data);
      createOrder(data).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          sessionStorage.removeItem("updateItemInfo");
        }
      });
    },

    // 确认下单
    addOrderPackage() {
      this.loading = true;
      let upData = {
        deliveryMode: "1", // 配送方式
        lineId: this.lineId, // 服务路线
        sender: { ...this.sender }, // 寄件人
        addressArr: [...this.addressArr], // 收件人
      };
      upData.addressArr.forEach((addressItem) => {
        addressItem.packageList.forEach((packegeItem) => {
          packegeItem.goodsList.forEach((goodsItem) => {
            console.log("goodsItem", goodsItem);
            console.log("weight", goodsItem["weight"]);
            goodsItem["weight"] = goodsItem["weight"]
              ? goodsItem["weight"]
              : goodsItem["suttle"];
          });
        });
      });
      console.log("upData", upData);
      // if (upData) return;
      addOrderPackage(upData)
        .then((res) => {
          if (res.data.code === 0) {
            setTimeout(() => {
              let sn = res.data.data;
              let updateItemInfo = JSON.parse(
                sessionStorage.getItem("updateItemInfo")
              );
              if (updateItemInfo && "whereSend" in updateItemInfo) {
                console.log(updateItemInfo);
                let id = updateItemInfo.id;
                let orderData = { package_id: id, sn: JSON.stringify(sn) };
                this.createOrderPost(orderData);
                updatePackageStatus("sn=" + JSON.stringify(sn)).then(
                  (changeRes) => {
                    console.log("changeRes ", changeRes);
                  }
                );
              }
              this.$router.push({
                path: `/ShoppingCart`,
              });
            }, 1000);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 选择了地址簿中的寄件人信息使用
    updateSender(arg) {
      this.sender.name = arg.name;
      this.sender.phone = arg.phone;
      this.sender.zipCode = arg.zipCode;
      this.sender.addressOne = arg.addressOne;
      this.sender.addressTwo = arg.addressTwo;
      this.sender.addressThree = arg.addressThree;
      this.sender.addressFour = arg.addressFour;
    },
    // 选择了地址簿中的收件人信息使用
    updateAddress(arg1, index) {
      this.addressArr[index].consignee.name = arg1.name;
      this.addressArr[index].consignee.identityNumber = arg1.identityNumber;
      this.addressArr[index].consignee.phone = arg1.phone;
      this.addressArr[index].consignee.province = arg1.province;
      this.addressArr[index].consignee.city = arg1.city;
      this.addressArr[index].consignee.county = arg1.county;
      this.addressArr[index].consignee.postCode = arg1.postCode;
      this.addressArr[index].consignee.addressDetail = arg1.addressDetail;
      this.addressArr[index].consignee.retained = arg1.retained;
    },
    // 点击了上传身份证
    uploadIDCardClicked() {
      console.log("点击了上传身份证");
      let routeUrl = this.$router.resolve({
        path: "/IdCardUpload",
      });
      window.open(routeUrl.href, "_blank");
    },
    // 点击了已知晓，进行下单
    orderClicked() {
      console.log("完成了服务条款阅读，进行了下单");
      this.addOrderPackage();
    },
  },
};
</script>
<style>
/* 抽屉的样式 */
.package-div .el-drawer__header {
  font-size: 16px;
  font-weight: 600;
  color: #1e2b4f;
  margin: 0px;
  padding: 14px 0px 14px 31px;
  border-bottom: 1px solid #f3f4fd;
}
</style>
<style lang="less" scoped>
.step3 {
  position: relative;
  padding-bottom: 170px;

  // 包裹清单按钮
  .package-btn {
    width: 36px;
    height: 260px;
    background: #6d5efe;
    border-radius: 8px 0px 0px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
    .icon-text {
      color: #fff;
      font-weight: bold;
      font-size: 16px;
    }
    .btn-text {
      width: 16px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 12px;
      line-height: 21px;
    }
    .package-count {
      width: 22px;
      height: 22px;
      background: #ffffff;
      border-radius: 50%;
      font-size: 14px;
      font-weight: bold;
      color: #6d5efe;
      line-height: 22px;
      text-align: center;
      margin-top: 10px;
    }
  }
  .package-right-btn {
    z-index: 10;
    position: fixed;
    top: 20%;
    right: 0px;
  }
  .package-close-btn {
    z-index: 10;
    position: fixed;
    top: 20%;
    right: 325px;
  }
  // 注意事项和服务条例
  .terms-div {
    // 修改对话框样式
    ::v-deep .el-dialog__wrapper {
      .el-dialog {
        border-radius: 8px;
        overflow: hidden;
        .el-dialog__header {
          padding: 0px;
        }
        .el-dialog__body {
          padding: 0px 0px;
        }
        .el-dialog__footer {
          padding: 30px 0px;
          display: flex;
          justify-content: center;
          .el-button--primary {
            margin-left: 20px;
            width: 264px;
            height: 44px;
            background: #6d5efe;
            border-color: #6d5efe;
            border-radius: 22px;
            padding: 0;
            span {
              font-size: 16px;
              font-weight: bold;
              color: #ffffff;
            }
          }
          .el-button--default {
            width: 72px;
            height: 44px;
            background: #ffffff;
            border-radius: 22px;
            border: 1px solid #d2d5dc;
            padding: 0;
            span {
              font-size: 16px;
              font-weight: 400;
              color: #636d88;
            }
          }
        }
      }
    }
    .dialog-content {
      padding: 30px 30px 0px 30px;
      max-height: 700px;
      overflow: auto;
      .title {
        height: 33px;
        font-size: 24px;
        font-weight: 600;
        color: #1e2b4f;
        line-height: 33px;
        margin-bottom: 12px;
      }
      .attention-div {
        margin-bottom: 30px;
        .content {
          .content-text {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #1e2b4f;
            line-height: 20px;
          }
          .red-text {
            color: #ff5353;
          }
          .blue-text {
            color: #6d5efe;
          }
        }
      }
    }
  }
  // 包裹清单抽屉
  .package-div {
    ::v-deep .el-drawer__wrapper {
      z-index: 2000 !important;
      min-height: calc(100vh - 101px);
      width: 325px;
      top: 101px;
      bottom: auto;
      left: auto;
      .el-drawer__container {
        min-height: calc(100vh - 101px);
      }
    }
    .package-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 7px;
      margin-bottom: 90px;
      .package-item {
        width: 285px;
        height: 162px;
        background: #f3f4fd;
        border-radius: 8px;
        padding: 12px;
        margin-top: 12px;
        .item-line {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .package-name {
            width: 145px;
            height: 17px;
            font-size: 12px;
            font-weight: 600;
            color: #6d5efe;
            line-height: 17px;
            display: flex;
            flex-direction: row;
            align-items: center;
            white-space: nowrap;
            .name {
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .package-amount {
            height: 17px;
            font-size: 12px;
            font-weight: 600;
            color: #ff6400;
            line-height: 17px;
          }
          .serve-name {
            width: 132px;
            height: 17px;
            font-size: 12px;
            font-weight: 600;
            color: #1e2b4f;
            line-height: 17px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .package-weight {
            height: 17px;
            font-size: 12px;
            font-weight: 600;
            color: #1e2b4f;
            line-height: 17px;
          }
          .line-text {
            height: 17px;
            font-size: 12px;
            font-weight: 400;
            color: #b1b6c3;
            line-height: 17px;
          }
        }
      }
    }
    .package-bottom {
      position: absolute;
      bottom: 0px;
      width: 325px;
      height: 82px;
      background: #6d5efe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 20px;
      .normal-text {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
      .package-count-div {
        display: flex;
        .count-text {
          color: #ffd45e;
        }
      }
      .package-acount-div {
        display: flex;
        align-items: center;
        .account-text {
          font-size: 20px;
          font-weight: 600;
          color: #ffd45e;
        }
      }
    }
  }
  // 提交
  .submit {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
    height: 100px;
    background: #fff;
    display: flex;
    justify-content: center;
    .submit-btn {
      display: inline-block;
      min-width: 220px;
      height: 44px;
      // line-height: 44px;
      border-radius: 22px;
      background: @bg-color;
      color: #fff;
      text-align: center;
      cursor: pointer;
      margin-top: 21px;
    }
  }
  // 服务介绍
  .service-introduction {
    margin-top: 20px;
    background: #fff;
    border-radius: 8px;
    padding: 70px;
    padding-top: 40px;
    .introduction-title {
      font-size: 24px;
      color: #1e2b4f;
      text-align: center;
      margin-bottom: 40px;
    }
  }
  .introduceText {
    font-size: 14px;
    line-height: 1.3;
  }
}
</style>

<template>
  <div class="step2">
    <div class="step2-title">
      <img class="step2-img" src="../../assets/img/order/step2.png" alt="">
      <span>自行安排</span>
    </div>
    <div class="step2-text">选择此服务即表示自行安排将包裹送至总部仓</div>
    <div class="step2-right">
      <div class="step2-btn" @click="nextStep">选择此项服务</div>
    </div>
    
  </div>
</template>
<script>
import {getAllInsurance} from '@/api/order/order'
export default {
  name:"step2",
  data(){
    return{

    }
  },
  created(){
    this.getAllInsurance();
  },
  methods:{
    // 获取保险接口
    getAllInsurance(){
      let upData = {
        lineId : this.$store.getters.getLineId,
      }
      getAllInsurance(upData).then(res => {
        if(res.data.code === 0){
          this.$store.state.InsuranceOptions = res.data.data.list
          this.$store.state.insurance = res.data.data.list[0].id
          console.log(this.$store.state.insurance,'this.$store.state.insurance')
        }
      })
    },
    nextStep(){
      let obj = {
        step : 3
      }
      this.$emit('nextStep', obj)
    }
  }
}
</script>
<style lang="less" scoped>
.step2 {
  width: 372px;
  background-color:#fff;
  border-radius: 8px;
  padding: 30px;
  .step2-img{
    width: 28px;
    margin-right: 12px;
    display:inline-block;
    vertical-align: middle;
    margin-top: -3px;
  }
  .step2-title{
    font-size: 20px;
    font-weight: 600;
    color: #1E2B4F;
  }
  .step2-text{
    font-size: 14px;
    color: #B1B6C3;
    margin-bottom: 30px;
    margin-top: 16px;
  }
  .step2-right{
    display:flex;
    flex-direction: row;
    justify-content: center;
  }
  .step2-btn{
    width: 150px;
    height: 44px;
    text-align:center;
    background-color:@bg-color;
    color: #fff;
    border-radius: 8px;
    line-height:44px;
    font-size: 16px;
    cursor: pointer;

  }
}
</style>
<template>
  <div class="step1">
    <div class="goods-box">
      <div class="goods-top">
        <div style="display:flex;">
          <div class="dest-box">
            <p class="goods-title mar-bottom-12">目的地</p>
            <p v-if="!inputInfo">{{indentData.whereReceive}}</p>
            <el-select v-model="indentData.whereReceive" v-else size="small" placeholder="请选择" class="where-receive">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="weight-box">
            <p class="goods-title mar-bottom-12">重量</p>
            <p v-if="!inputInfo">{{indentData.weight}}KG</p>
            <p v-else>
              <el-tooltip  popper-class="my-tooltip" :manual=true :value="!indentCheck.weight" content="请输入此项" placement="top">
                <div>
                  <el-input v-model="indentData.weight" size="small" class="weight" placeholder="重量"  @input="limitInput($event,'weight')" @change="indentCheck.weight=true"></el-input>
                  KG
                </div>
              </el-tooltip>
            </p>
            
          </div>
          <div class="size-box">
            <p class="goods-title mar-bottom-12">尺寸</p>
            <p v-if="!inputInfo">{{indentData.length + 'cm X ' + indentData.width + 'cm X ' + indentData.height + 'cm'}} </p>
            <p v-else style="display: flex;">
              <el-tooltip  popper-class="my-tooltip" :manual=true :value="!indentCheck.length" content="请输入此项" placement="top">
                <div>
                  <el-input v-model="indentData.length" size="small" class="weight" placeholder="长度" @input="(v) => (indentData.length = v.replace(/[^\d]/g, ''))" @change="indentCheck.length=true"></el-input>
                  <span style="margin-right:4px"> cm X </span>
                </div>
              </el-tooltip>
              <el-tooltip  popper-class="my-tooltip" :manual=true :value="!indentCheck.width" content="请输入此项" placement="top">
                <div>
                  <el-input v-model="indentData.width" size="small" class="weight" placeholder="宽度" @input="(v) => (indentData.width = v.replace(/[^\d]/g, ''))" @change="indentCheck.width=true"></el-input>
                  <span style="margin-right:4px"> cm X </span>
                </div>
              </el-tooltip>
              <el-tooltip  popper-class="my-tooltip" :manual=true :value="!indentCheck.height" content="请输入此项" placement="top">
                <div>
                  <el-input v-model="indentData.height" size="small" class="weight" placeholder="高度" @input="(v) => (indentData.height = v.replace(/[^\d]/g, ''))" @change="indentCheck.height=true"></el-input>
                  <span> cm</span>
                </div>
              </el-tooltip>
            </p>
          </div>
        </div>
        <div>
          <span class="goods-title mar-right-12">运费估计</span>
          <span class="amount-title">{{inputInfo ? '--' : `￡${minPrice}起`}}</span>
        </div>
      </div>
      <div class="goods-bottom">
        <div class="set-info" :class="{estimate: inputInfo}" @click="setInfo">{{inputInfo ? '重新估算' : '修改信息'}}</div>
      </div>
      
    </div>
    <div class="routes">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="all">
          <div class="service-item" v-for="(item,index) in serviceArr" :key="index">
            <img class="item-logo" :src="item.logoPath" alt="">
            <div class="item-content">
              <div class="service-name">{{item.serviceName}}</div>
              <div class="service-status">
                <span class="service-duration">正常时效：{{item.timeliness}}</span>
                <span class="status">{{item.recentStatus}}</span>
              </div>
              <div class="service-text">
                {{item.note}}
              </div>
              <!-- <div class="service-info-btn">
                服务详情
              </div> -->
            </div>
            <div class="service-item-operation">
              <span v-if="!item.able" style="line-height:28px;">{{item.describeOrMoney}}</span>
              <div v-else>
                <div class="mar-bottom-12">
                  <span class="freight">运费：</span>
                  <span class="amount">￡{{item.describeOrMoney}}</span>
                </div>
                <div class="item-service-btn" @click="choiceThis(item)">选择此服务</div>
              </div>
            </div>
          </div>
          <!-- <div class="service-item">
            <img class="item-logo" src="../../assets/img/home/home_order_icon.png" alt="">
            <div class="item-content">
              <div class="service-name">两罐奶粉</div>
              <div class="service-status">
                <span class="service-duration">正常时效：约7-12天</span>
                <span class="status">近期实效正常</span>
              </div>
              <div class="service-text">
                奶粉包税直邮, 全程追踪，800g奶粉可邮2罐，400g奶粉可邮4罐！
              </div>
              <div class="service-info-btn">
                服务详情
              </div>
            </div>
            <div class="service-item-operation">
              包裹重量必须小于1.00kg
            </div>
          </div> -->
          
        </el-tab-pane>
        <el-tab-pane v-for="(item,index) in tabArr" :key="index" :label="item.name" :name="item.id + ''">
          <!-- eslint-disable-line -->
          <div v-for="(item,index) in screenServiceArr" :key="index" class="service-item">
              <img class="item-logo" :src="item.logoPath" alt="">
              <div class="item-content">
                <div class="service-name">{{item.serviceName}}</div>
                <div class="service-status">
                  <span class="service-duration">正常时效：{{item.timeliness}}</span>
                  <span class="status">{{item.recentStatus}}</span>
                </div>
                <div class="service-text">
                  {{item.note}}
                </div>
                <!-- <div class="service-info-btn">
                  服务详情
                </div> -->
              </div>
              <div class="service-item-operation">
                <span v-if="!item.able" style="line-height:28px;">{{item.describeOrMoney}}</span>
                <div v-else>
                  <div class="mar-bottom-12">
                    <span class="freight">运费：</span>
                    <span class="amount">￡{{item.describeOrMoney}}</span>
                  </div>
                  <div class="item-service-btn" @click="choiceThis(item)">选择此服务</div>
                </div>
              </div>
            
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>


import {showLineTypeList,queryServiceInfo} from '@/api/order/order'
export default {
  name: "step1",
  data(){
    return{
      //可选择的国家
      options: [
        {
          value: 'CN',
          label: 'CN 中国大陆'
        }
      ],
      // 当前选中的服务路线
      activeName:'all',
      minPrice:0, // 最低价格
      inputInfo: false,
      //订单信息
      indentData:{
        //发出地
        whereSend: "CN",
        //接收地
        whereReceive: "CN",
        //重量
        weight: "",
        //长度
        length: "",
        //宽度
        width: "",
        //高度
        height: "",
      },
      //检测订单输入是否合规
      indentCheck:{
         //重量
        weight: true,
        //长度
        length: true,
        //宽度
        width: true,
        //高度
        height: true,
      },
      // 服务菜单数组
      tabArr:[],
      // 所有服务数组
      serviceArr:[],
      // 筛选所选服务后数组
      screenServiceArr:[],
    }
  },
  created(){
    let itemInfo = this.$store.getters.getItemInfo;
    let dress = this.$store.getters.getDest
    this.indentData = {...itemInfo, ...{whereReceive: dress}};
    console.log(itemInfo)
    let updateItemInfo = JSON.parse(sessionStorage.getItem("updateItemInfo"));
    console.log(updateItemInfo);
    console.log()
    if(updateItemInfo && "whereSend" in updateItemInfo){
      console.log(updateItemInfo);
      this.indentData = {...updateItemInfo};
      this.$store.dispatch('updateItemInfo', this.indentData);
    }
   
    this.init()
  },
  methods:{
    init(){
      this.showLineTypeList();
      this.queryServiceInfo()
    },
    // 获取服务路线分类
    showLineTypeList(){
      showLineTypeList().then(res => {
        if(res.data.code === 0){
          this.tabArr = res.data.data.list;
        }
      })
    },
    // 获取所有的服务路线
    queryServiceInfo(){
      let upData = {
        toCountry:'', // 目的地暂时搁置
        weight:this.indentData.weight,
      }
      queryServiceInfo(upData).then(res => {
        if(res.data.code === 0) {
          this.serviceArr = res.data.data.list;
          this.minPrice = res.data.data.minPrice;
        }else{
          this.$message.error(res.data.message)
        }
      })
    },
    limitInput(value, name) {
      this.indentData[name] =
        ("" + value) // 第一步：转成字符串
          .replace(/[^\d^\\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
    },
    // 选择此项服务
    choiceThis(item){
      console.log(item,'item')
      this.$store.dispatch('updateLineId',item.id)
      let obj = {
        step:2
      }
      sessionStorage.setItem('severName',item.serviceName)
      this.$emit('nextStep', obj)
    },
    // 修改信息 / 重新估算
    setInfo(){
      if(this.inputInfo){
        if(!this.indentData.weight){
          this.indentCheck.weight = false;
          return;
        }
        if(!this.indentData.length){
          this.indentCheck.length = false;
          return;
        }
        if(!this.indentData.width){
          this.indentCheck.width = false;
          return;
        }
        if(!this.indentData.height){
          this.indentCheck.height = false;
          return;
        }
        // 根据重量查询服务路线
        this.queryServiceInfo();
        console.log("存储")
        this.$store.dispatch('updateItemInfo', this.indentData)
      }
      this.inputInfo = !this.inputInfo;
    },
    // 切换tab
    handleClick(){
      let serviceArr = this.serviceArr;
      this.screenServiceArr = [];
      for(let i = 0; i<serviceArr.length; i++){
        if(serviceArr[i].typeId == this.activeName){
          this.screenServiceArr.push(serviceArr[i])
        }
      }
      
    },
    
  }
}
</script>
<style>
/* tooltip 自定义样式 */
.my-tooltip.is-dark{
  background: #FF6400;
;
}
.my-tooltip[x-placement^=top] .popper__arrow::after{
  border-top-color: #FF6400;
}
.my-tooltip[x-placement^=top] .popper__arrow{
  border-top-color: #FF6400;
}
</style>
<style lang="less" scoped>
.step1{
  padding-bottom: 70px;

}
.goods-box{

  width: 100%;
  height: 200px;
  border-radius: 8px;
  background-color:#fff;
  // 物品信息
  .goods-top{
    height: 120px;
    border-bottom: 1px solid #D2D5DC;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    font-size: 16px;
    color: #1E2B4F;
    // 目的地
    .dest-box{
      padding-right: 50px;
      border-right: 1px solid #D2D5DC;
      .where-receive{
        width: 140px;
        &::v-deep .el-input__inner { 
          border-radius: 22px;
        }
      }
    }
    // 重量
    .weight-box{
      padding:0 50px;
    }
    .weight{
      width: 64px;
      &::v-deep .el-input__inner { 
        border-radius: 22px;
      }
    }
    // 尺寸
    .size-box{
      padding-left: 50px;
      border-left: 1px solid #D2D5DC;
    }
    // 金额
    .amount-title{
      color: #FF7E57;
      font-size: 28px;
      font-weight: 600;
    }
  }
  .goods-bottom{
    display:flex;
    flex-direction:row-reverse;
    margin: 20px 30px;
    // 修改信息
    .set-info{
      border:1px solid #6D5EFE;
      height:36px;
      width: 80px;
      border-radius: 22px;
      color: #6D5EFE;
      line-height:36px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
    }
    .estimate{
      background-color: @bg-color;
      color: #fff;
    }
  }
  
  .goods-title{
    font-size: 14px;
    color: #636D88;
  }
  
}
.mar-bottom-12{
  margin-bottom: 12px;
}
.mar-right-12{
  margin-right: 12px;
}
// 内容展示
.routes{
  margin-top: 20px;
  background-color: #fff;
  border-radius: 8px;
  // 调整菜单表头的高度
  &::v-deep .el-tabs__item{
    height: 70px;
    line-height:70px;
    padding:0 20px !important;
  }
  &::v-deep .el-tabs__header{
    margin:0;
  }
  &::v-deep .el-tabs__item:hover,
  &::v-deep .el-tabs__item.is-active{
    color: @bg-color;
  }
  &::v-deep .el-tabs__active-bar{
    background-color:@bg-color;
  }

  // 每个服务
  .service-item{
    padding: 40px 32px 40px 38px;
    display: flex;
    &:nth-child(2n+1){
      background-color:#F3F4FD;
    }
    // 服务logo
    .item-logo{
      width: 80px;
      height: 80px;
      margin-right: 40px;
      border-radius: 8px;
    }
    // 服务内容状态
    .item-content{
      flex:1;
      padding-right: 60px;
      color: #1E2B4F;
      // 服务名称
      .service-name{
        font-size: 20px;
        color: #1E2B4F;
        font-weight: 600;
        margin-bottom: 16px;
      }
      // 服务时长，状态
      .service-status{
        font-size: 14px;
        margin-bottom: 16px;
        // 服务时长
        .service-duration{
          display: inline-block;
          width: 200px;
        }
        .status{
          position: relative;
          padding-left: 15px;
        }
        // 服务状态
        .status::before{
          content:'';
          width: 6px;
          height: 6px;
          border-radius: 50%;
          display: inline-block;
          background-color: @bg-color;
          position:absolute;
          top: 50%;
          left:0;
          transform: translateY(-50%);
        }
      }
      // 服务文案
      .service-text{
        font-size: 14px;
        color: #636D88;
        margin-bottom: 12px;
      }
      // 服务详情按钮
      .service-info-btn{
        font-size: 14px;
        color: @bg-color;
        font-weight: 600;
        cursor: pointer;
      }
    }
    // 服务操作按钮盒子
    .service-item-operation{
      width: 160px;
      display: flex;
      align-items: center;
      .freight{
        color:#636D88;
        font-size: 16px;

      }
      .amount{
        font-size: 20px;
        font-weight: bold;
        color: #FF7E57;
        text-align: right;
        display:inline-block;
      }
      .item-service-btn{
        width: 100%;
        color:#fff;
        cursor: pointer;
        text-align: center;
        height:44px;
        line-height: 44px;
        border-radius: 8px;
        background-color: @bg-color;

      }
    }
  }
  .noPadding{
    padding:0;
  }
}
</style>